import React, { useEffect, useState, useMemo } from "react";
import { Link, graphql } from "gatsby";
import { isFuture } from "date-fns";

import PortableText from "../components/portable-text";
import {
  useWindowSize,
  useMousePosition,
  useCounter,
  useAllImagesLoaded
} from "../util/hooks";
import { isRenderingOnClient } from "../util/constants";
import SEO from "../components/seo";

import * as styles from "./index.module.css";

import logo from "../images/logo.svg";
import bg1 from "../images/bg-cycle-1.png";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawHomepageContent(resolveReferences: { maxDepth: 5 })
    }
    cycles: allSanityCycle(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          publishedAt
          slug {
            current
          }
        }
      }
    }
  }
`;

const TILE_SIZE = 80;
function Blocker() {
  // While the image is loading, we set the container to have a white
  // background; that way, the underlying text won’t briefly flash on page
  // load.
  const [containerStyleExtras, setContainerStyleExtras] = React.useState({
    backgroundColor: "var(--c-white)"
  });

  const [hits, setHits] = useState({});
  const [mouseX, mouseY] = useMousePosition(isRenderingOnClient && window);
  const [windowWidth, , windowHeight, remeasure] = useWindowSize();
  const nTilesX = Math.ceil(windowWidth / TILE_SIZE);
  const nTilesY = Math.ceil(windowHeight / TILE_SIZE);
  const nTiles = nTilesX * nTilesY;

  // On a big screen, it can be cumbersome to hit every single tile. This
  // effect helps the user out by randomly removing neighbors of tiles that
  // have already been hit.
  const { count } = useCounter({ frequency: 300, immediate: true });
  useEffect(() => {
    setHits((prevHits) => {
      const newHits = { ...prevHits };
      let limit = 3; // Max 3 free hits per turn.
      Object.keys(newHits).forEach((key) => {
        if (limit <= 0) return;
        const [x, y] = key.split("-").map(Number);
        if (newHits[key]) {
          for (let dx = -1; dx <= 1; dx++) {
            for (let dy = -1; dy <= 1; dy++) {
              if (dx === 0 && dy === 0) continue;
              const nx = x + dx;
              const ny = y + dy;
              if (nx < 0 || nx >= nTilesX || ny < 0 || ny >= nTilesY) continue;
              const neighbor = `${nx}-${ny}`;
              if (!newHits[neighbor] && Math.random() < 0.01) {
                newHits[neighbor] = true;
                if (--limit <= 0) return;
              }
            }
          }
        }
      });
      return newHits;
    });
  }, [count, setHits, nTilesX, nTilesY]);

  // Since we’re breaking the image up into tiles, `background-size: cover`
  // won’t work the same as with the full-page background image behind it. We
  // want the two to be the same visual size, so we need to manually calculate
  // the `cover` size by checking the document’s aspect ratio vs. the image’s.
  //
  // First, load the image and store its raw dimensions.
  const [[rawImageWidth, rawImageHeight], setImageSize] = useState([
    null,
    null
  ]);
  useEffect(() => {
    const img = new Image();
    img.addEventListener("load", function () {
      setImageSize([img.width, img.height]);
      setContainerStyleExtras({});
    });
    img.src = bg1;
  }, []);
  // Then, compare it with the document’s aspect ratio for the final
  // `background-size` dimensions.
  const [imageWidth, imageHeight] = useMemo(() => {
    if (!rawImageWidth || !rawImageHeight || !windowWidth || !windowHeight)
      return [null, null];
    const aspectX = windowWidth / rawImageWidth;
    const aspectY = windowHeight / rawImageHeight;
    const aspect = Math.max(aspectX, aspectY);
    return [rawImageWidth * aspect, rawImageHeight * aspect];
  }, [rawImageWidth, rawImageHeight, windowWidth, windowHeight]);

  // This effect is what actually knocks out the tiles on mouseover.
  useEffect(() => {
    if (mouseX == null || mouseY == null) return;
    const x = Math.floor(mouseX / TILE_SIZE);
    const y = Math.floor(mouseY / TILE_SIZE);
    const key = `${x}-${y}`;
    setHits((prevHits) => {
      if (prevHits[key]) return prevHits;
      return { ...prevHits, [key]: true };
    });
  }, [mouseX, mouseY, setHits]);

  // Remeasure the window once all images have loaded in case the document
  // height changes and our numbers are slightly off.
  const isLoaded = useAllImagesLoaded();
  useEffect(() => {
    if (isLoaded) {
      remeasure();
    }
  }, [isLoaded, remeasure]);

  // We get some weird rendering issues if containerStyle is different on the
  // (SSR) and the client during rehydration.
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    setHasRendered(true);
  }, []);
  const containerStyle = useMemo(() => {
    const style = { ...containerStyleExtras };
    if (hasRendered) {
      style.gridTemplate = `repeat(${nTilesY}, ${TILE_SIZE}px) / repeat(${nTilesX}, ${TILE_SIZE}px)`;
    }
    return style;
  }, [nTilesX, nTilesY, containerStyleExtras, hasRendered]);

  return (
    <div aria-hidden="true" className={styles.blocker} style={containerStyle}>
      {isLoaded &&
        Array.from({ length: nTiles }).map((_, i) => {
          const x = i % nTilesX;
          const y = Math.floor(i / nTilesX);
          // Tiles are indexed by string keys instead of in arrays. This is so
          // it can handle resizes better; otherwise we would need to resize a
          // multidimensional array on the fly… in state… which gets messy.
          const key = `${x}-${y}`;
          return (
            <div
              key={key}
              style={{
                backgroundImage: hits[key] ? "none" : `url(${bg1})`,
                // Note: `backgroundPosition` needs to be manually set if
                //       `backgroundAttachment` is not "fixed". Like so:
                // backgroundPosition: `top ${-TILE_SIZE * y}px left ${
                //   -TILE_SIZE * x
                // }px`,
                backgroundSize: `${imageWidth}px ${imageHeight}px`,
                backgroundAttachment: "fixed"
              }}
            />
          );
        })}
    </div>
  );
}

const IndexPage = ({ data = {} }) => {
  const { site, cycles } = data;
  const latestCycle =
    cycles?.edges.filter(({ node }) => !isFuture(new Date(node.publishedAt)))[0]
      ?.node.slug.current ?? "context";

  return (
    <>
      <SEO />
      <div className={styles.root}>
        <div className={styles.description}>
          <PortableText blocks={site._rawHomepageContent} />
        </div>
        <Blocker />
        <Link to={`/${latestCycle}`} className={styles.logoLink}>
          <img src={logo} alt="Go the latest CHTHONIC cycle" />
        </Link>
      </div>
    </>
  );
};

export default IndexPage;
